<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-snackbar v-model="snackbar" timeout="2000" top color="amber">
        Имэйлүүдийг xуулсан:
        <h1>
          ({{ snackbarText }})
          <span v-if="selectedSchool">{{ selectedSchool.name }}</span>
        </h1>
        <template v-slot:action="{ attrs }">
          <v-btn
            class="font-weight-bold"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Xааx
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="border-radius-xl card-shadow">
        <h5 class="font-weight-bold text-h2 text-typo mx-4 py-10">
          Token үүсгэлт
          <v-btn @click="_updateTokens()" class="mb-2 bg-gradient-danger" dark>
            Update Tokens</v-btn
          >
        </h5>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          v-if="filteredSchools && filteredSchools.length > 0"
          :items="filteredSchools"
          :items-per-page="500"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td style="cursor: pointer">
                {{ props.item.schoolName }}
              </td>

              <td>{{ _getFormatedDate(props.item.tokenTakenAt) }}</td>
              <td>{{ props.item.autoGenerated }}</td>
              <td>{{ _getFormatedDate(props.item.autoGeneratedAt) }}</td>
              <td class="blue--text">
                {{
                  props.item.token.substring(0, 10) +
                  " ... " +
                  props.item.token.substring(
                    props.item.token.length - 20,
                    props.item.token.length
                  )
                }}
              </td>
              <td>
                <v-btn
                  icon
                  @click="_copyToken(props.item)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card v-else class="text-center" height="300">
          <v-row justify="center">
            <v-img
              class="mx-auto mt-16"
              max-height="100"
              max-width="100"
              src="/assets/img/notfound.png"
            ></v-img>
          </v-row>
          <h5 class="py-10 text-danger">Мэдээлэл оруулаагүй байна</h5>
        </v-card>
      </v-card>

      <div class="py-3" />
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import moment from "moment";
import { sync } from "vuex-pathify";
import axios from "axios";

export default {
  name: "RegularTablesView",
  data: () => ({
    showDateSelectDialog: false,
    selectedDate: null,
    snackbarText: null,
    selectedSchool: null,
    snackbar: false,
    logLists: null,
    logLists2: null,
    selectedCity: null,
    moreDetailed: false,
    bulkQuestionsAdd: false,
    bulkText: null,
    loading: false,
    error: false,
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,

    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
      phoneRequired: (value) => !!value || "Утас шаардлагатай.",
      nameRequired: (value) => !!value || "Нэр, овог шаардлагатай.",
    },
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredSchools() {
      return this.items;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Сургуулийн нэр",
          align: "start",
          sortable: true,
          value: "schoolName",
          width: "20%",
        },

        {
          text: "Авсан огноо",
          align: "start",
          sortable: true,
          value: "tokenTakenAt",
        },
        {
          text: "autoGenerated",
          align: "start",
          sortable: true,
          value: "autoGenerated",
        },
        {
          text: "autoGeneratedAt",
          align: "start",
          sortable: true,
          value: "autoGeneratedAt",
        },
      ];
    },
  },
  created() {
    if (this.moreDetailed) {
      this.headers.concat([
        {
          text: "Имэйл",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Захирал",
          align: "start",
          sortable: true,
          value: "directorName",
        },

        {
          text: "Заxирлын утас",
          align: "start",
          sortable: true,
          value: "directorPhone",
        },

        {
          text: "Б.тоо",
          align: "start",
          sortable: true,
          value: "teachersNumber",
        },
        {
          text: "С.тоо",
          align: "start",
          sortable: true,
          value: "studentsNumber",
        },
        {
          text: "G.тоо",
          align: "start",
          sortable: true,
          value: "numberOfGroups",
        },
      ]);
    }
    fb.db
      .collection("_tokenAktual2")
      .orderBy("schoolName", "asc")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        var index = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;

          item.index = ++index;
          item.loading = false;
          item.loadingGoups = false;
          item.loadingTeachers = false;

          this.items.push(item);
        });
      });
  },

  methods: {
    _copyToken(school){
      navigator.clipboard.writeText(school.token)
    },
    _updateSchoolToken(school, counter) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          if (res.data.token) {
            fb.db
              .doc("/2/" + school.id)
              .set(
                {
                  token: res.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: school.name,
                  autoGenerated: true,
                  autoGeneratedAt: new Date(),
                },
                { merge: true }
              )
              .then(() => {
                counter++;
                console.log(counter, school.name);
              });
          }
        });
    },

    _getFormatedDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
